import SignatureUsageTable from '@/components/billing/SignatureUsageTable.vue';
import LayoutAdmin from '@/components/layouts/LayoutAdmin.vue';
import AccountActivityTable from '~/components/activity/AccountActivityTable.vue';

// FIXME: There is Vuetify bug preventing us from using human-readable values as shared models
// See: https://github.com/vuetifyjs/vuetify/issues/10540

export default defineComponent({
  components: {
    AccountActivityTable: AccountActivityTable,
    LayoutAdmin: LayoutAdmin,
    SignatureUsageTable: SignatureUsageTable
  },
  data: function data() {
    return {
      selectedTab: '0',
      loadingTable: true
    };
  },
  head: function head() {
    return {
      title: 'Business - Activity'
    };
  },
  watch: {
    $route: {
      handler: function handler(newRoute) {
        var _newRoute$query$tab;
        this.selectedTab = (_newRoute$query$tab = newRoute.query.tab) !== null && _newRoute$query$tab !== void 0 ? _newRoute$query$tab : '0';
      },
      immediate: true
    }
  }
});