import "core-js/modules/es.object.assign.js";
import formatRelative from 'date-fns/formatRelative';
import { de, enGB, frCH, it } from 'date-fns/locale';
import { useI18n } from 'nuxt-i18n-composable';
export default defineComponent({
  props: {
    date: String
  },
  setup: function setup() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    var _useI18n = useI18n(),
      locale = _useI18n.locale;
    return {
      currentLocale: locale
    };
  },
  computed: {
    value: function value() {
      var formatRelativeLocale = {
        lastWeek: 'PP',
        nextWeek: 'PP',
        other: 'PP'
      };
      var locale = {};
      switch (this.currentLocale) {
        case 'de-ch':
          formatRelativeLocale.yesterday = "'Gestern'";
          formatRelativeLocale.today = "'Heute'";
          formatRelativeLocale.tomorrow = "'Morgen'";
          Object.assign(locale, de);
          break;
        case 'fr-ch':
          formatRelativeLocale.yesterday = "'Hier'";
          formatRelativeLocale.today = "'Aujourd’hui'";
          formatRelativeLocale.tomorrow = "'Demain'";
          Object.assign(locale, frCH);
          break;
        case 'en-gb':
          formatRelativeLocale.yesterday = "'Yesterday'";
          formatRelativeLocale.today = "'Today'";
          formatRelativeLocale.tomorrow = "'Tomorrow'";
          Object.assign(locale, enGB);
          break;
        case 'it':
          formatRelativeLocale.yesterday = "'Ieri'";
          formatRelativeLocale.today = "'Oggi'";
          formatRelativeLocale.tomorrow = "'Domani'";
          Object.assign(locale, it);
          break;
      }
      locale.formatRelative = function (token) {
        return formatRelativeLocale[token];
      };
      return formatRelative(new Date(this.date), new Date(), {
        locale: locale
      });
    }
  }
});